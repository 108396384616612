import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Login from './include/login'
import Register from './include/register'
import Front from './include/front'
import App from './App'
import { Provider } from 'react-redux'
import store from './store'


import reportWebVitals from './reportWebVitals';

let path = window.location.pathname

const Layout = () => {
  let ret = ''
  const { user: currentUser } = useSelector((state) => state.auth)
  console.log(currentUser)
  let inter = ''
  if (path === '/register') {
    inter = <Register />
    console.log('test', path)
    if (localStorage.getItem('isRefresh') === undefined) {
      localStorage.setItem('isRefresh', true)
      window.location.reload()
    }
  }
  if (path === '/login' || path === '/') {
    inter = <Login />
    console.log('test', path)
    if (localStorage.getItem('isRefresh') === undefined) {
      localStorage.setItem('isRefresh', true)
      window.location.reload()
    }
  }
  if (path === '/') {
    inter = <Front />
    console.log('test', path)
    if (localStorage.getItem('isRefresh') === undefined) {
      localStorage.setItem('isRefresh', true)
      window.location.reload()
    }
  }
  currentUser ? (ret = <App />) : (ret = inter)

  return ret
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Layout />
    </Provider>
  </BrowserRouter>
)


reportWebVitals();
