import React from 'react'

const Header = () => {
  return (
    <header className='header'>
      <div className='header-fixed'>
        <nav className='navbar navbar-expand-lg header-nav'>
          <div className='navbar-header'>
            <a id='mobile_btn' href='/'>
              <span className='bar-icon'>
                <span></span>
                <span></span>
                <span></span>
              </span>
            </a>
            <a href='index.html' className='navbar-brand logo'>
              <img
                src='/assets/img/logo.png'
                className='img-fluid'
                alt='Logo'
              />
            </a>
          </div>
          <div className='main-menu-wrapper'>
            <div className='menu-header'>
              <a href='index.html' className='menu-logo'>
                <img
                  src='/assets/img/logo.png'
                  className='img-fluid'
                  alt='Logo'
                />
              </a>
              <a id='menu_close' className='menu-close' href='/'>
                <i className='fas fa-times'></i>
              </a>
            </div>
            <ul className='main-nav'>
              <li className='active has-submenu'>
                <a href='index.html'>
                  Home <i className='fas fa-chevron-down'></i>
                </a>
                <ul className='submenu'>
                  <li className='active'>
                    <a href='index.html'>Home</a>
                  </li>
                  <li>
                    <a href='index-two.html'>Home 2</a>
                  </li>
                  <li>
                    <a href='index-three.html'>Home 3</a>
                  </li>
                  <li>
                    <a href='index-four.html'>Home 4</a>
                  </li>
                  <li>
                    <a href='index-five.html'>Home 5</a>
                  </li>
                  <li>
                    <a href='index-six.html'>Home 6</a>
                  </li>
                  <li>
                    <a href='index-seven.html'>Home 7</a>
                  </li>
                </ul>
              </li>
              <li className='has-submenu'>
                <a href>
                  Mentor <i className='fas fa-chevron-down'></i>
                </a>
                <ul className='submenu'>
                  <li>
                    <a href='dashboard.html'>Mentor Dashboard</a>
                  </li>
                  <li>
                    <a href='bookings.html'>Bookings</a>
                  </li>
                  <li>
                    <a href='schedule-timings.html'>Schedule Timing</a>
                  </li>
                  <li>
                    <a href='mentee-list.html'>Mentee List</a>
                  </li>
                  <li>
                    <a href='profile-mentee.html'>Mentee Profile</a>
                  </li>
                  <li className='has-submenu'>
                    <a href='blog.html'>Blog</a>
                    <ul className='submenu'>
                      <li>
                        <a href='blog.html'>Blog</a>
                      </li>
                      <li>
                        <a href='blog-details.html'>Blog View</a>
                      </li>
                      <li>
                        <a href='add-blog.html'>Add Blog</a>
                      </li>
                      <li>
                        <a href='edit-blog.html'>Edit Blog</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href='chat.html'>Chat</a>
                  </li>
                  <li>
                    <a href='invoices.html'>Invoices</a>
                  </li>
                  <li>
                    <a href='profile-settings.html'>Profile Settings</a>
                  </li>
                  <li>
                    <a href='reviews.html'>Reviews</a>
                  </li>
                  <li>
                    <a href='mentor-register.html'>Mentor Register</a>
                  </li>
                </ul>
              </li>
              <li className='has-submenu'>
                <a href>
                  Mentee <i className='fas fa-chevron-down'></i>
                </a>
                <ul className='submenu'>
                  <li className='has-submenu'>
                    <a href='/'>Mentors</a>
                    <ul className='submenu'>
                      <li>
                        <a href='map-grid.html'>Map Grid</a>
                      </li>
                      <li>
                        <a href='map-list.html'>Map List</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href='search.html'>Search Mentor</a>
                  </li>
                  <li>
                    <a href='profile.html'>Mentor Profile</a>
                  </li>
                  <li>
                    <a href='bookings-mentee.html'>Bookings</a>
                  </li>
                  <li>
                    <a href='checkout.html'>Checkout</a>
                  </li>
                  <li>
                    <a href='booking-success.html'>Booking Success</a>
                  </li>
                  <li>
                    <a href='dashboard-mentee.html'>Mentee Dashboard</a>
                  </li>
                  <li>
                    <a href='favourites.html'>Favourites</a>
                  </li>
                  <li>
                    <a href='chat-mentee.html'>Chat</a>
                  </li>
                  <li>
                    <a href='profile-settings-mentee.html'>Profile Settings</a>
                  </li>
                  <li>
                    <a href='change-password.html'>Change Password</a>
                  </li>
                </ul>
              </li>
              <li className='has-submenu'>
                <a href>
                  Pages <i className='fas fa-chevron-down'></i>
                </a>
                <ul className='submenu'>
                  <li>
                    <a href='voice-call.html'>Voice Call</a>
                  </li>
                  <li>
                    <a href='video-call.html'>Video Call</a>
                  </li>
                  <li>
                    <a href='search.html'>Search Mentors</a>
                  </li>
                  <li>
                    <a href='components.html'>Components</a>
                  </li>
                  <li className='has-submenu'>
                    <a href='invoices.html'>Invoices</a>
                    <ul className='submenu'>
                      <li>
                        <a href='invoices.html'>Invoices</a>
                      </li>
                      <li>
                        <a href='invoice-view.html'>Invoice View</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href='blank-page.html'>Starter Page</a>
                  </li>
                  <li>
                    <a href='login.html'>Login</a>
                  </li>
                  <li>
                    <a href='register.html'>Register</a>
                  </li>
                  <li>
                    <a href='forgot-password.html'>Forgot Password</a>
                  </li>
                </ul>
              </li>
              <li className='has-submenu'>
                <a href>
                  Blog <i className='fas fa-chevron-down'></i>
                </a>
                <ul className='submenu'>
                  <li>
                    <a href='blog-list.html'>Blog List</a>
                  </li>
                  <li>
                    <a href='blog-grid.html'>Blog Grid</a>
                  </li>
                  <li>
                    <a href='blog-details.html'>Blog Details</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href='admin/index.html' target='_blank'>
                  Admin
                </a>
              </li>
              <li className='login-link'>
                <a href='login.html'>Login / Signup</a>
              </li>
            </ul>
          </div>
          <ul className='nav header-navbar-rht'>
            <li className='nav-item'>
              <a className='nav-link' href='login.html'>
                Login
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link header-login' href='register.html'>
                Register
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
