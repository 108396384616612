import React from 'react'

const Register = () => {
  return (
    <div>
      <div className='main-wrapper'>
        <div className='bg-pattern-style bg-pattern-style-register'>
          <div className='content'>
            <div className='account-content'>
              <div className='account-box'>
                <div className='login-right'>
                  <div className='login-header'>
                    <h3>
                      <span>
                        <a href='/'>CHIAVI ITALIE</a>
                      </span>{' '}
                      Register
                    </h3>
                    <p className='text-muted'>Access to our dashboard</p>
                  </div>

                  <form>
                    <div className='row'>
                      <div className='col-lg-6'>
                        <div className='form-group'>
                          <label className='form-control-label'>
                            First Name
                          </label>
                          <input
                            id='first-name'
                            type='text'
                            className='form-control'
                            name='first_name'
                            autofocus
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className='form-group'>
                          <label className='form-control-label'>
                            Last Name
                          </label>
                          <input
                            id='last-name'
                            type='text'
                            className='form-control'
                            name='last_name'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='form-group'>
                      <label className='form-control-label'>
                        Email Address
                      </label>
                      <input id='email' type='email' className='form-control' />
                    </div>
                    <div className='row'>
                      <div className='col-lg-6'>
                        <div className='form-group'>
                          <label className='form-control-label'>Phone</label>
                          <input
                            id='Phone-confirm'
                            type='text'
                            className='form-control'
                            name='phone'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className='form-group'>
                          <label className='form-control-label'>Password</label>
                          <input
                            id='password'
                            type='password'
                            className='form-control'
                            name='password'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='form-group'>
                      <div className='form-check form-check-xs custom-checkbox'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          name='agreeCheckboxUser'
                          id='agree_checkbox_user'
                        />
                        <label
                          className='form-check-label'
                          for='agree_checkbox_user'
                        >
                          I agree to Mentoring
                        </label>{' '}
                        <a tabindex='-1' href='javascript:void(0);'>
                          Privacy Policy
                        </a>{' '}
                        &amp;{' '}
                        <a tabindex='-1' href='javascript:void(0);'>
                          {' '}
                          Terms.
                        </a>
                      </div>
                    </div>
                    <button className='btn btn-primary login-btn' type='submit'>
                      Create Account
                    </button>
                    <div className='account-footer text-center mt-3'>
                      Already have an account?{' '}
                      <a className='forgot-link mb-0' href='/login'>
                        Login
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register
