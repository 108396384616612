import React from 'react'

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-top aos'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-3 col-md-6'>
              <div className='footer-widget footer-about'>
                <div className='footer-logo'>
                  <img src='/assets/img/logo.png' alt='logo' />
                </div>
                <div className='footer-about-content'>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.{' '}
                  </p>
                  <div className='social-icon'>
                    <ul>
                      <li>
                        <a href='/' target='_blank'>
                          <i className='fab fa-facebook-f'></i>{' '}
                        </a>
                      </li>
                      <li>
                        <a href='/' target='_blank'>
                          <i className='fab fa-twitter'></i>{' '}
                        </a>
                      </li>
                      <li>
                        <a href='/' target='_blank'>
                          <i className='fab fa-linkedin-in'></i>
                        </a>
                      </li>
                      <li>
                        <a href='/' target='_blank'>
                          <i className='fab fa-instagram'></i>
                        </a>
                      </li>
                      <li>
                        <a href='/' target='_blank'>
                          <i className='fab fa-dribbble'></i>{' '}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='footer-widget footer-menu'>
                <h2 className='footer-title'>For Mentee</h2>
                <ul>
                  <li>
                    <a href='search.html'>Search Mentors</a>
                  </li>
                  <li>
                    <a href='login.html'>Login</a>
                  </li>
                  <li>
                    <a href='register.html'>Register</a>
                  </li>
                  <li>
                    <a href='booking.html'>Booking</a>
                  </li>
                  <li>
                    <a href='dashboard-mentee.html'>Mentee Dashboard</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='footer-widget footer-menu'>
                <h2 className='footer-title'>For Mentors</h2>
                <ul>
                  <li>
                    <a href='appointments.html'>Appointments</a>
                  </li>
                  <li>
                    <a href='chat.html'>Chat</a>
                  </li>
                  <li>
                    <a href='login.html'>Login</a>
                  </li>
                  <li>
                    <a href='register.html'>Register</a>
                  </li>
                  <li>
                    <a href='dashboard.html'>Mentor Dashboard</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='footer-widget footer-contact'>
                <h2 className='footer-title'>Contact Us</h2>
                <div className='footer-contact-info'>
                  <div className='footer-address'>
                    <span>
                      <i className='fas fa-map-marker-alt'></i>
                    </span>
                    <p>
                      {' '}
                      3556 Beech Street, San Francisco,
                      <br /> California, CA 94108{' '}
                    </p>
                  </div>
                  <p>
                    <i className='fas fa-phone-alt'></i>
                    +1 315 369 5943
                  </p>
                  <p className='mb-0'>
                    <i className='fas fa-envelope'></i>
                    info@mentormatch.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='footer-bottom'>
        <div className='container-fluid'>
          <div className='copyright'>
            <div className='row'>
              <div className='col-12 text-center'>
                <div className='copyright-text'>
                  <p className='mb-0'>
                    &copy; 2022 Mentoring. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
