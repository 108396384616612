import React, { useState, useEffect } from 'react'
import { Routes, Route, Link } from 'react-router-dom'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'

//utils & action
import { logout } from './actions/auth'
import { clearMessage } from './actions/message'
import { history } from './helpers/history'

//layout
import Header from './layout/header'
import Front from './pages/front'
import Footer from './layout/footer'
//pages


function App() {
  return (
    <div className='main-wrapper'>
      <Header />

      <Front />

      <Footer />
    </div>
  )
}

export default App
