import React from 'react'

const Front = () => {
  return (
    <div>
      <div className='main-wrapper'>
        <header className='header'>
          <div className='header-fixed'>
            <nav className='navbar navbar-expand-lg header-nav'>
              <div className='navbar-header'>
                <a id='mobile_btn' href='javascript:void(0);'>
                  <span className='bar-icon'>
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </a>
                <a href='/' className='navbar-brand logo'>
                  <img
                    src='assets/img/logo.png'
                    className='img-fluid'
                    alt='Logo'
                  />
                </a>
              </div>
              <div className='main-menu-wrapper'>
                <div className='menu-header'>
                  <a href='/' className='menu-logo'>
                    <img
                      src='assets/img/logo.png'
                      className='img-fluid'
                      alt='Logo'
                    />
                  </a>
                  <a
                    id='menu_close'
                    className='menu-close'
                    href='javascript:void(0);'
                  >
                    <i className='fas fa-times'></i>
                  </a>
                </div>
                <ul className='main-nav'>
                  <li className='login-link'>
                    <a href='/login'>Login / Signup</a>
                  </li>
                </ul>
              </div>
              <ul className='nav header-navbar-rht'>
                <li className='nav-item'>
                  <a className='nav-link' href='/login'>
                    Login
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link header-login' href='/register'>
                    Register
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>

        <section className=' home-slide home-slide-three d-flex align-items-center'>
          <div className='container'>
            <div className='row '>
              <div className='col-md-6 d-flex align-items-center'>
                <div className='section-banner-three aos '>
                  <div className='hometwo-slide-text '>
                    <h1>
                      Your Italian <br />
                      Adventure Awaits
                      <br />
                    </h1>
                    <p>
                      Start your Italian adventure today. Whether you're
                      planning to <b>immigrate</b>, <b>study</b>, or simply
                      explore, let us guide you on your journey to discovering
                      the magic of Italy.
                    </p>
                  </div>

                  <div className='search-box'>
                    <form action='https://mentoring.dreamstechnologies.com/html/template//login'>
                      <div className='form-group search-info location-search'>
                        <a
                          href='/login'
                          className='btn bg-warning search-btn align-items-center d-flex justify-content-center'
                        >
                          Join the community
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='object-slide-img aos '>
                  <img src='assets/img/object-4.png' alt />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='section how-it-works'>
          <div className='container'>
            <div className='section-header text-center aos'>
              <span>Mentoring Flow</span>
              <h2>How does it works ?</h2>
              <p className='sub-title'>
                With our mentoring flow, you'll have a trusted advisor by your
                side, empowering you to achieve your goals and thrive in Italy.
                Take the first step towards your Italian journey today and let
                our mentors help you turn your dreams into reality.
              </p>
            </div>
            <div className='row'>
              <div className='col-12 col-md-6 col-lg-4'>
                <div className='feature-box text-center aos'>
                  <div className='feature-header'>
                    <div className='feature-icon'>
                      <span className='circle'></span>
                      <i>
                        <img src='assets/img/icon-1.png' alt />
                      </i>
                    </div>
                    <div className='feature-cont'>
                      <div className='feature-text'>
                        Sign Up and Share Your Goals
                      </div>
                    </div>
                  </div>
                  <p className='mb-0'>
                    Kickstart your journey by signing up and sharing your
                    aspirations and objectives with us.
                  </p>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-4'>
                <div className='feature-box text-center aos'>
                  <div className='feature-header'>
                    <div className='feature-icon'>
                      <span className='circle'></span>
                      <i>
                        <img src='assets/img/icon-2.png' alt />
                      </i>
                    </div>
                    <div className='feature-cont'>
                      <div className='feature-text'>
                        Customized Mentor Matching
                      </div>
                    </div>
                  </div>
                  <p className='mb-0'>
                    Once you've signed up, we'll match you with a mentor who
                    aligns with your ambitions and experiences.
                  </p>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-4'>
                <div className='feature-box text-center aos'>
                  <div className='feature-header'>
                    <div className='feature-icon'>
                      <span className='circle'></span>
                      <i>
                        <img src='assets/img/icon-3.png' alt />
                      </i>
                    </div>
                    <div className='feature-cont'>
                      <div className='feature-text'>Guidance Every Step</div>
                    </div>
                  </div>
                  <p className='mb-0'>
                    From initial planning to settling into your new life in
                    Italy, your mentor will be there for you at every stage of
                    the process.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='section path-section'>
          <div className='section-header text-center aos'>
            <div className='container'>
              <span>Choose the</span>
              <h2>Different All Learning Paths</h2>
              <p className='sub-title'>
                Embark on your Italian adventure by selecting the path that
                aligns with your goals and aspirations
              </p>
            </div>
          </div>
          <div className='learning-path-col'>
            <div className='container'>
              <div className='row'>
                <div className='col-12 col-md-4 col-lg-3'>
                  <div className='large-col aos'>
                    <a href='/login' className='large-col-image'>
                      <div className='image-col-merge'>
                        <img src='assets/img/path-img1.jpg' alt />
                        <div className='text-col'>
                          <h5>Traditional Academia</h5>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className='col-12 col-md-4 col-lg-3'>
                  <div className='large-col aos'>
                    <a href='/login' className='large-col-image'>
                      <div className='image-col-merge'>
                        <img src='assets/img/path-img2.jpg' alt />
                        <div className='text-col'>
                          <h5>Online Learning Platforms</h5>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className='col-12 col-md-4 col-lg-3'>
                  <div className='large-col aos'>
                    <a href='/login' className='large-col-image'>
                      <div className='image-col-merge'>
                        <img src='assets/img/path-img3.jpg' alt />
                        <div className='text-col'>
                          <h5>Language Schools</h5>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className='col-12 col-md-4 col-lg-3'>
                  <div className='large-col aos'>
                    <a href='/login' className='large-col-image'>
                      <div className='image-col-merge'>
                        <img src='assets/img/path-img4.jpg' alt />
                        <div className='text-col'>
                          <h5>Vocational Training Institutes</h5>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className='col-12 col-md-4 col-lg-3'>
                  <div className='large-col aos'>
                    <a href='/login' className='large-col-image'>
                      <div className='image-col-merge'>
                        <img src='assets/img/path-img5.jpg' alt />
                        <div className='text-col'>
                          <h5>Study Abroad Programs</h5>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className='col-12 col-md-4 col-lg-3'>
                  <div className='large-col aos'>
                    <a href='/login' className='large-col-image'>
                      <div className='image-col-merge'>
                        <img src='assets/img/path-img6.jpg' alt />
                        <div className='text-col'>
                          <h5>Internship Opportunities</h5>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className='col-12 col-md-4 col-lg-3'>
                  <div className='large-col aos'>
                    <a href='/login' className='large-col-image'>
                      <div className='image-col-merge'>
                        <img src='assets/img/path-img7.jpg' alt />
                        <div className='text-col'>
                          <h5>Cultural Immersion Programs</h5>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className='col-12 col-md-4 col-lg-3'>
                  <div className='large-col aos'>
                    <a href='/login' className='large-col-image'>
                      <div className='image-col-merge'>
                        <img src='assets/img/path-img8.jpg' alt />
                        <div className='text-col'>
                          <h5>Specialized Short Courses</h5>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='section statistics-section'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 col-md-4'>
                <div className='statistics-list text-center aos'>
                  <span>52+</span>
                  <h3>Happy Clients</h3>
                </div>
              </div>
              <div className='col-12 col-md-4'>
                <div className='statistics-list text-center aos'>
                  <span>120+</span>
                  <h3>Online Appointments</h3>
                </div>
              </div>
              <div className='col-12 col-md-4'>
                <div className='statistics-list text-center aos'>
                  <span>100%</span>
                  <h3>Job Satisfaction</h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='new-course subscribe-course'>
          <div className='container'>
            <div className='new-course-background'>
              <div className='row'>
                <div className='col-lg-9 col-md-6 d-flex align-items-center'>
                  <div className='every-new-course aos '>
                    <div className='new-course-text'>
                      <h1>
                        Subscribe For Get Updates Every
                        <br /> New Programs
                      </h1>
                      <p className='page-sub-text '>
                        Stay informed about the latest programs and
                        opportunities in Italy <br />
                        by subscribing to our newsletter.
                      </p>
                    </div>
                    <div className='course-mail'>
                      <div className='join-arrow'>
                        <img src='assets/img/join-arrow.png' alt />
                      </div>
                      <form action='#'>
                        <div className='input-group mb-3 subscribe-form'>
                          <input
                            type='text'
                            className='form-control course-text-bg'
                            placeholder='Enter your mail'
                          />
                          <a href='/login' className='btn bg-course-subscribe'>
                            Subscribe
                          </a>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6'>
                  <div className='new-course-img aos '>
                    <img src='assets/img/join-5.png' alt='new course' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className='footer'>
          <div className='footer-top aos'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-lg-3 col-md-6'>
                  <div className='footer-widget footer-about'>
                    <div className='footer-logo'>
                      <img src='assets/img/logo.png' alt='logo' />
                    </div>
                    <div className='footer-about-content'>
                      <p>
                        Immerse yourself in the rich culture, history, and
                        beauty of Italy. From the rolling hills of Tuscany to
                        the bustling streets of Rome, explore a land where every
                        corner tells a story.
                      </p>
                      <div className='social-icon'>
                        <ul>
                          <li>
                            <a
                              href='https://www.facebook.com/profile.php?id=61555016995046'
                              target='_blank'
                            >
                              <i className='fab fa-facebook-f'></i>{' '}
                            </a>
                          </li>
                          <li>
                            <a
                              href='https://www.facebook.com/profile.php?id=61555016995046'
                              target='_blank'
                            >
                              <i className='fab fa-twitter'></i>{' '}
                            </a>
                          </li>
                          <li>
                            <a
                              href='https://www.facebook.com/profile.php?id=61555016995046'
                              target='_blank'
                            >
                              <i className='fab fa-linkedin-in'></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href='https://www.facebook.com/profile.php?id=61555016995046'
                              target='_blank'
                            >
                              <i className='fab fa-instagram'></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6'>
                  <div className='footer-widget footer-menu'>
                    <h2 className='footer-title'>For Mentee</h2>
                    <ul>
                      <li>
                        <a href='/login'>Search Mentors</a>
                      </li>
                      <li>
                        <a href='/login'>Login</a>
                      </li>
                      <li>
                        <a href='/login'>Register</a>
                      </li>
                      <li>
                        <a href='/login'>Booking</a>
                      </li>
                      <li>
                        <a href='/login'>Mentee Dashboard</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6'>
                  <div className='footer-widget footer-menu'>
                    <h2 className='footer-title'>For Mentors</h2>
                    <ul>
                      <li>
                        <a href='/login'>Appointments</a>
                      </li>
                      <li>
                        <a href='/login'>Chat</a>
                      </li>
                      <li>
                        <a href='/login'>Login</a>
                      </li>
                      <li>
                        <a href='/login'>Register</a>
                      </li>
                      <li>
                        <a href='/login'>Mentor Dashboard</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6'>
                  <div className='footer-widget footer-contact'>
                    <h2 className='footer-title'>Contact Us</h2>
                    <div className='footer-contact-info'>
                      <div className='footer-address'>
                        <span>
                          <i className='fas fa-map-marker-alt'></i>
                        </span>
                        <p>
                          {' '}
                          IMM TAMAYOUZ APP 1-1،
                          <br /> CENTRE URB NORD 1082 Tunis{' '}
                        </p>
                      </div>
                      <p>
                        <i className='fas fa-phone-alt'></i>
                        +216 54 046 033
                      </p>
                      <p className='mb-0'>
                        <i className='fas fa-envelope'></i>
                        <a
                          href=''
                          className='__cf_email__'
                          data-cfemail='412c242f352e33282f26012439202c312d246f222e2c'
                        >
                          contact@chiavi-italie.net
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='footer-bottom'>
            <div className='container-fluid'>
              <div className='copyright'>
                <div className='row'>
                  <div className='col-12 text-center'>
                    <div className='copyright-text'>
                      <p className='mb-0'>
                        &copy; 2024 Chiavi Itali. All rights reserved.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Front
